import React from "react";
import PropTypes from "prop-types";
import Layout from "../components/Layout";
import Heading from "../components/Heading";
import { graphql } from "gatsby";
import Img from "gatsby-image";

const AboutPage = ({ data, location }) => {
  const pageData = data.prismicAbout.data;

  return (
    <Layout
      pageTheme="dark"
      backLink={"/"}
      pageColor={"black"}
      seoTitle={pageData.meta_title || "About"}
      seoDescription={pageData.meta_description}
      location={location}
    >
      <Heading text={pageData.title} className="mb-8 lg:mb-12" />
      <div className="flex flex-wrap md:justify-between lg:pb-16 -ml-gs">
        <div className="w-full md:w-4/12 md:order-last pl-gs pb-12">
          {pageData.image.fluid && (
            <Img fluid={pageData.image.fluid} alt={pageData.image.alt} />
          )}
        </div>
        <div className="w-full md:w-7/12 md:order-first pl-gs lg:text-md leading-normal">
          {pageData.rich_text.html && (
            <div
              className="space-y-4"
              dangerouslySetInnerHTML={{
                __html: pageData.rich_text.html
              }}
            ></div>
          )}
        </div>
      </div>
    </Layout>
  );
};

AboutPage.propTypes = {
  location: PropTypes.object.isRequired
};

export default AboutPage;

export const query = graphql`
  query {
    prismicAbout {
      uid
      data {
        title
        image {
          fluid(srcSetBreakpoints: [380, 640, 1280]) {
            aspectRatio
            sizes
            srcSet
            srcSetWebp
            srcWebp
            src
          }
          alt
        }
        meta_description
        meta_title
        rich_text {
          html
        }
      }
    }
  }
`;
