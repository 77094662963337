import React from "react";

const Heading = ({ text, count, className }) => {
  if (!text) return false;
  return (
    <h3
      className={`flex text-lg md:text-xl lg:text-2xl xl:text-3xl${
        className ? ` ${className}` : ""
      }`}
    >
      {text}
      {count && (
        <span className="text-xs md:text-sm lg:text-base">{count}</span>
      )}
    </h3>
  );
};

export default Heading;
